import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';

function Model({modelPath}: any  ) {
    // @ts-ignore
    const { scene } = useGLTF(modelPath);
    return <primitive object={scene} scale={0.5} />;
}

function ModelViewer() {
    return (
        <Canvas style={{ height: '100vh', width: '100vw' }}>
            <Suspense fallback={null}>
        {/* Replace 'path_to_your_model.glb' with the actual path to your model */}
                <Model modelPath="/sketchfab_3d_editor_challenge_littlest_tokyo.glb" />
            </Suspense>

    {/* Camera controls */}
    <OrbitControls />
    </Canvas>
);
}

export default ModelViewer;
