import {Route, Routes} from "react-router-dom";
import {SpacedRepetitionInterface} from "./spaced_repetition/interface";
import {SRConfigUI} from "./spaced_repetition/SRconfig/SRConfigUI";
import {Home} from "./home/home";
import {TaskBoard} from "./task_board/TaskBoard";
import {QuoteInterface} from "./quotes/quoteInterface";
import {GuideInterface} from "./guides/guideInterface";
import {TrackingSystem} from "./source_tracking_system/TrackingSystem";
import {Planner} from "./planner/planner";
import {ReadingListInterface} from "./reading_list/readingListInterface";
import {Object_database_ui} from "./object_database/object_database_ui";
import {SearchInterface} from "./search/searchInterface";
import {CalendarInterface} from "./calendar/interface/interface";
import {CardOverview} from "./card_overview/CardOverview";
import LoginPage from "./authentication/login_page";
import {Contact} from "./contact/contact";
import React, {useContext} from "react";
import {AuthContext} from "./authentication/auth_context";
import {About} from "./about/about";
import {InterfaceV2} from "./spacedRepetitionV2/interfaceV2";
import {KnowledgeGraph} from "./knowledgeGraph/knowledgeGraph";


export function Router() {
    //@ts-ignore
    const {isLoggedIn, setIsLoggedIn} = useContext(AuthContext);


    return (
        <Routes>
            {isLoggedIn && <Route path="/spaced_repetition" element={<SpacedRepetitionInterface />} />}
            {isLoggedIn && <Route path="/spaced_repetition/config" element={<SRConfigUI />} />}
            <Route path="/card/:id" element={<SpacedRepetitionInterface />} />
            <Route path="/" element={<Home />} />
            <Route path="/task_board" element={<TaskBoard />} />
            <Route path="/add_quote" element={<QuoteInterface />} />
            <Route path="/guides" element={<GuideInterface/>} />
                {isLoggedIn && <Route path="/source_tracking" element={<TrackingSystem/>} />}
                {isLoggedIn && <Route path="/planner" element={<Planner/>} />}
                {isLoggedIn && <Route path="/reading_list" element={<ReadingListInterface/>} />}
            <Route path="/object_database" element={<Object_database_ui/>} />
            <Route path="/object_database/:object" element={<Object_database_ui/>} />
            <Route path="/search" element={<SearchInterface />} />
            <Route path="/calendar" element={<CalendarInterface />} />
            <Route path="/cards/from_reference/:id" element={<CardOverview />} />
            <Route path="/cards/from_tag/:id" element={<CardOverview />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/spaced_repetition_v2" element={<InterfaceV2 />} />
            <Route path="/spaced_repetition_v2/:id" element={<InterfaceV2 />} />
            <Route path="/knowledge_graph" element={<KnowledgeGraph />} />
        </Routes>
    )
}